import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Hero,
  StaticProjects,
  ReactProjects,
  HeadlessProjects,
  FullStackProjects,
} from "../../containers"
import { BreadCrumb, TitleV2 } from "../../components"

import ProjectCrumbs from "../../constants/BreadCrumbs/ProjectCrumbs"

const ProjectsPage = ({ data }) => {
  const image = getImage(
    data.strapiProjectPage.image.localFile.childImageSharp.gatsbyImageData
  )

  //console.log("Project page - project cards:", figmaCards)

  return (
    <section>
      <Hero image={image} title={data.strapiProjectPage.title} />
      <BreadCrumb crumbs={ProjectCrumbs} />
      <div className="uk-container uk-margin-small-top">
        <div>
          <TitleV2 title="Our Projects" className="uk-padding-remove-bottom" />
        </div>
        <div>
          welcome to our projects section, below are just a few of our complete
          projects prototypes and themes
        </div>
      </div>

      <section className="uk-section uk-section-default uk-padding-remove-top uk-padding-remove-bottom ">
        <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom	">
          <h3 className="uk-heading-line uk-text-bold">
            <span>React JS Projects</span>
          </h3>
          <ReactProjects />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove-top uk-padding-remove-bottom ">
        <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom	">
          <h3 className="uk-heading-line uk-text-bold">
            <span>Static Generator Projects</span>
          </h3>
          <StaticProjects />
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom ">
        <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom	">
          <h3 className="uk-heading-line uk-text-bold">
            <span>Full-Stack Projects</span>
          </h3>
          <FullStackProjects />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove-top uk-padding-remove-bottom ">
        <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom	">
          <h3 className="uk-heading-line uk-text-bold">
            <span>Headless CMS Projects</span>
          </h3>
          <HeadlessProjects />
        </div>
      </section>

      <br />
      <br />
    </section>
  )
}

export default ProjectsPage

export const query = graphql`
  {
    strapiProjectPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
